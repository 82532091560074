import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "kitchen.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              aspectRatio
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        file: file(relativePath: { eq: "kitchen.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            fluid {
              aspectRatio
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      
      return (
        <div className="my-big-image">
          <BackgroundImage
            Tag="section"
            className={'bgImage ' + className}
            fluid={imageData}
            backgroundColor={`#040e18`}
          ></BackgroundImage>
        </div>
      )
    }}
  />
)

export default BackgroundSection
