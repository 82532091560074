import React, { Fragment } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import './style.scss'

const HowToOrderOption = ({ option, link }) => {
  const data = useStaticQuery(
    graphql`
      query {
        onlineShop: file(relativePath: { eq: "online-shop.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            fixed(width: 300, height: 250) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        orderFrom: file(relativePath: { eq: "order-form.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            fixed(width: 300, height: 250) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        onlineDesigner: file(relativePath: { eq: "online-designer.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            fixed(width: 300, height: 250) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

  let optionImage = ''
  switch (option) {
    case 'Online Shop':
      optionImage = data.onlineShop.childImageSharp.fixed
      break
    case 'Order Form':
      optionImage = data.orderFrom.childImageSharp.fixed
      break
    case 'Online Designer':
      optionImage = data.onlineDesigner.childImageSharp.fixed
      break
    default:
      optionImage = null
      break
  }

  return (
    <div className="card blacksection me-5">
      <a href={link}>
        <Img
          className="card-image-top rounded"
          fixed={optionImage}
          alt="logo"
        />
      </a>
      <div className="card-body">
        <h2 className="card-title">{option}</h2>
      </div>
    </div>
  )
}

const HowToOrderSection = () => {
  return (
    <Fragment>
      <div className="flex-row blacksection pt-3">
        <div className="d-flex justify-content-center">
          <h1 className="text-primary text-reset">How To Order</h1>
        </div>
      </div>
      <div className="flex-row blacksection">
        <div className="d-flex justify-content-center ">
              <HowToOrderOption option="Online Shop" link="/under-construction" />
              <HowToOrderOption option="Order Form" link="/order-form" />
              <HowToOrderOption option="Online Designer" link ="/online-designer" />
        </div>
      </div>
    </Fragment>
  )
}

export default HowToOrderSection