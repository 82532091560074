import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import './style.scss'

const ProductCategory = ({ location, title }) => {
  const data = useStaticQuery(
    graphql`
      query {
        file: file(relativePath: { eq: "warderobe.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            fixed(width: 310, height: 250) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

  return (
      <div className="card border-0 me-5">
        <Link to={location}>
          <Img
            className="card-image-top rounded"
            fixed={data.file.childImageSharp.fixed}
            alt="logo"
          />
        </Link>
        <div className="card-title">
          <Link to={location}>
            <h2 className="text-dark">{title}</h2>
          </Link>
        </div>
      </div>
  )
}

export default ProductCategory