import React, { Fragment } from 'react'
import './style.scss'
import ProductCategory from 'components/product-category'

const ProductsSection = ({ location, title }) => {
  return (
    <Fragment>
      <div className="flex-row mt-3">
        <div className="d-flex justify-content-around">
          <h1 className="text-primary"> Products</h1>
        </div>
      </div>
        <div className="d-flex justify-content-center">
              <ProductCategory location="/under-construction" title="Kitchen" />
              <ProductCategory
                location="/under-construction"
                title="Wardrobe"
              />
              <ProductCategory
                location="/under-construction"
                title="Vanities"
              />
      </div>
        <div className="d-flex justify-content-center">
              <ProductCategory
                location="/under-construction"
                title="Laundries"
              />
              <ProductCategory
                location="/under-construction"
                title="TV Units"
              />
              <ProductCategory
                location="/under-construction"
                title="Shop Fit Outs"
              />
      </div>
    </Fragment>
  )
}
export default ProductsSection