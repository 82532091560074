import { useStaticQuery, graphql, Link } from 'gatsby'
import React from 'react'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Post from 'templates/post'
import Meta from 'components/meta'
import Layout from 'components/layout'
import StyledBackgroundSection from 'components/background-imge'
import ProductsSection from 'components/products-section'
import HowToOrderSection from 'components/how-to-order-section'
import TestimonialsSection from 'components/testimonials-section'

const BlogIndex = ({ data, location }) => {
  const posts = get(data, 'remark.posts')

  return (
    <Layout location={location}>
      <Meta site={get(data, 'site.meta')} />
      <StyledBackgroundSection />
      <ProductsSection />
      <HowToOrderSection />
      <TestimonialsSection />

      {/* {posts.map(({ post }, i) => (
        <Post
          data={post}
          options={{
            isIndex: true,
          }}
          key={i}
        />
      ))} */}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query IndexQuery {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
        twitter
        adsense
      }
    }
    remark: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      posts: edges {
        post: node {
          html
          frontmatter {
            layout
            title
            path
            category
            tags
            description
            date(formatString: "YYYY/MM/DD")
            image {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
