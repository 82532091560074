import React, { Fragment } from 'react'
import './style.scss'

const Testimonial = ({ name, text }) => {
  return (
      <div className="card border-0 blacksection mx-5">
        <div className="card-body">
          <blockquote className="blockquote text-left">
            <p className="card-text">
              "Best Flat Pack place! On time and on spec. Can't find a better
              shop."
            </p>
          </blockquote>
        </div>
        <div class="card-footer d-flex align-items-center whitesection">
          <img
            class="card-image-bottom avatar rounded-circle mx-2"
            src="https://s3.eu-central-1.amazonaws.com/bootstrapbaymisc/blog/24_days_bootstrap/robert.jpg"
            alt="Bologna"
          />
          <small>
           <strong>Johny Handyman</strong><br/>
           Handyman's R Us
           May, 3 2019
          </small>
        </div>
      </div>
  )
}

const Testimonials = () => {
  return (
    <Fragment>
      <div className="flex-row mt-3">
        <div className="d-flex justify-content-center">
          <h1 className="text-primary"> Testimonials </h1>
        </div>
      </div>
        <div className="d-flex justify-content-center">
              <Testimonial />
              <Testimonial />
              <Testimonial />
      </div>
    </Fragment>
  )
}

export default Testimonials